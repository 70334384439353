import { defineAsyncComponent } from 'vue'

import MobileDetect from 'mobile-detect';

const Parse = require('parse/node');
import sharedhome from './sharedhome.js';

var timeOutId;

export default {
  inject: ['devEnv'],
  userDataStruct(){
    return {
      todaysTips: "",
      weekTipsArray: [],
      dayToHighlight: 0,
      paymentsArray: [
        {
          otherPartyName: "",
        }, {
          otherPartyName: "",
        }, {
          otherPartyName: "",
        }, {
          otherPartyName: "",
        }, {
          otherPartyName: "",
        }
          // these just create the correct number of records in advance to populate with data
      ],
      accountBalance: "",
      userCurrencySymbol: "", 
      email: "",
      isRecipient: false,
      displayname: "",
      receiveLast4: undefined,
      accountNameAbbr: undefined,
      paymentMethod: "",
      paymentLast4: "",
      paymentCardBrand: "",
      selectedPaymentObjectId: "",
      selectedPaymentObject: "",
      userObjectId: "",
      selectedPayoutId: "",
      nextScreen: "",
      fromScreen: "",
      goStraightToPhotoEdit: false,
      hasGivenTipsBefore: false,
      hasReceivedTipsBefore: false,
      venuesConnectedAtArray: [],
      isPoolMaster: false,
      tipHistoryMode: "",
      mostRecentSalonVisited: "",
      mostRecentSalonVisitedTUID: "",
      stripeBalancesObject: {},
    };
  },
  sendAppAlert(subjectMsg, bodyMsg, appId, appJSKey){
    

    // console.log("calling Parse");

    Parse.serverURL = 'https://parseapi.back4app.com/'; Parse.initialize(appId, appJSKey); 

    Parse.Cloud.run("sendEmail", {
    toEmail: "appalert@thanku.app", 
    subject: subjectMsg,
    body: bodyMsg === undefined ? "": bodyMsg,
    });
  },
  isDesktop(){
    var ua = navigator.userAgent.toLowerCase();
    var isDesktop = !(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(ua)||/1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(ua.substr(0,4)));

    return isDesktop;
  },

  getDeviceDetails(){
    var detector = new MobileDetect(window.navigator.userAgent);

    var deviceType;

    if (detector.phone() !== null) {
      deviceType = detector.phone();
    } else if (detector.tablet() !== null) {
      deviceType = detector.tablet();
    } else if (this.isDesktop()) {
      deviceType = "Desktop";
    } else {
      deviceType = "Unknown";
    }

    var OS = "not detected"; 

    
    if (detector.os() !== null && detector.os() !== undefined) {
      OS = detector.os();
    } else {

      // window.navigator.platform is now deprecated and there isn't a replacement because they want you to do feature detection instead which is a pain and not necessary as we don't use the OS field for anything

      // had a user with a bug, probably wasn't dont to this, but instead /Linux/.test(platform) which had a bum !os &&  at the front of it?!?!

      // but thought, if deprecated, at some point this code would break every user so get rid of it

      // var userAgent = window.navigator.userAgent,
      //     platform = window.navigator.platform,
      //     macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
      //     windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
      //     iosPlatforms = ['iPhone', 'iPad', 'iPod'],
      //     OS = "Unknown";

      // if (macosPlatforms.indexOf(platform) !== -1) {
      //   OS = 'Mac OS';
      // } else if (iosPlatforms.indexOf(platform) !== -1) {
      //   OS = 'iOS';
      // } else if (windowsPlatforms.indexOf(platform) !== -1) {
      //   OS = 'Windows';
      // } else if (/Android/.test(userAgent)) {
      //   OS = 'Android';
      // } else if (/Linux/.test(platform)) {
      //   OS = 'Linux';
      // } else {
      //   OS = 'web';
      // }
    }

    return {
      mobile: detector.mobile(),
      type: deviceType,
      OS: OS,
      userAgent: window.navigator.userAgent,
    }

  },

  getDeviceFullObject(){

    const locale = this.getNavigatorLanguage();
    
    const usersDateTimeConfig = this.getUsersDateTimeConfig();
    const deviceIsDaylightSavingTime = usersDateTimeConfig.deviceIsDaylightSavingTime;
    const deviceDaylightSavingTimeOffset = usersDateTimeConfig.deviceDaylightSavingTimeOffset;
    const deviceSecondsFromGMT = usersDateTimeConfig.deviceSecondsFromGMT;
    const deviceLocalTimeZoneIdentifier = usersDateTimeConfig.deviceLocalTimeZoneIdentifier;

    const deviceDetails = this.getDeviceDetails();
    const deviceType = deviceDetails.type;
    const mobile = deviceDetails.mobile;
    const deviceOS = deviceDetails.OS;
    const userAgent = deviceDetails.userAgent;

    return {
      locale: locale,
      deviceIsDaylightSavingTime: deviceIsDaylightSavingTime,
      deviceDaylightSavingTimeOffset: deviceDaylightSavingTimeOffset,
      deviceSecondsFromGMT: deviceSecondsFromGMT,
      deviceLocalTimeZoneIdentifier: deviceLocalTimeZoneIdentifier,
      deviceType: deviceType,
      mobile: mobile,
      deviceOS: deviceOS,
      userAgent: userAgent
    }
  },

  getNavigatorLanguage() {

    // /console.log("navigator.userLanguage::: " + JSON.stringify(navigator.userLanguage, null, 2));
    // /console.log("navigator.language::: " + JSON.stringify(navigator.language, null, 2));
    // /console.log("navigator.browserLanguage::: " + JSON.stringify(navigator.browserLanguage, null, 2));

    // /console.log("navigator.languages[0]::: " + JSON.stringify(navigator.languages[0], null, 2));
    if (navigator.languages && navigator.languages.length) {
      let language = navigator.languages[0];
      if (language === 'en'){
        language = 'en-GB';
      } else if (language === 'ja'){
        language = 'ja-JP';
      }
      return language;
    } else {
      let language = navigator.userLanguage || navigator.language || navigator.browserLanguage || 'en-GB';
      if (language === 'en'){
        language = 'en-GB';
      } else if (language === 'ja'){
        language = 'ja-JP';
      }
      return language;

    }
  },

  
  getUsersDateTimeConfig(){

    // var d = new Date(2021, 2, 14, 2, 0, 0, 0); // this is browser's date time - user Dev Tools 3 dots, more tools Sensors to set the browser to San Fran - this is the exact moment that DST in 2021 came into effect for San Francisco
    // var d = new Date('March 14, 2021 02:00:00 GMT-08:00'); // this is exactly the same moment in time
    // var d = new Date(2021, 9, 3, 23, 14, 58, 0); // this is the day before BEGINNING of DST in Sydney: 27 Oct 2021 at 23:14:58 UTC
    
    // /console.log("timestamp::: " + new Date()); // this gives a timestamp of the browser's current date and time

    var d = new Date();

    var deviceSecondsFromGMT = d.getTimezoneOffset() * -60; // ALL we actually need is the user's current time at the time of the transaction - no one needs to be aware of whether or not it is DST
    
    return {
      deviceIsDaylightSavingTime: undefined,
      deviceDaylightSavingTimeOffset: undefined,
      deviceSecondsFromGMT: deviceSecondsFromGMT,
      deviceLocalTimeZoneIdentifier: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
   
  },
  OLDgetUsersDateTimeConfig(){

    // var d = new Date(2021, 2, 14, 2, 0, 0, 0); // this is browser's date time - user Dev Tools 3 dots, more tools Sensors to set the browser to San Fran - this is the exact moment that DST in 2021 came into effect for San Francisco
    // var d = new Date('March 14, 2021 02:00:00 GMT-08:00'); // this is exactly the same moment in time
    // var d = new Date(2021, 9, 3, 23, 14, 58, 0); // this is the day before BEGINNING of DST in Sydney: 27 Oct 2021 at 23:14:58 UTC
    var d = new Date();

    var todayOffset = d.getTimezoneOffset();
    
    // /console.log("DATE::: " + JSON.stringify(d, null, 2));

    var arr = [];
    var newoffset;
    for (var i = 0; i < 365; i++) {
      d.setDate(i);
      newoffset = d.getTimezoneOffset();

      // // /console.log("Date:::: " + d.getDate() + "  offset::: " + newoffset);
      arr.push(newoffset);
    }

    // /console.log("Date:::: " + JSON.stringify(d, null, 2) + "  offset::: " + d.getTimezoneOffset());

    // // /console.log("Timezone array::: " + JSON.stringify(arr, null, 2));

    const DST = Math.min.apply(null, arr);
    const nonDST = Math.max.apply(null, arr);

    // /console.log("DST:: " + DST + " nonDST:: " + nonDST);

    var deviceDaylightSavingTimeOffset;

    if (todayOffset == DST){
      // I'm not sure how necessary this is but to the extent that the device timezone offset, this records it - it is NOT additional to the offset but an element of it
      deviceDaylightSavingTimeOffset = -(todayOffset - nonDST)*60;
    } else {
      deviceDaylightSavingTimeOffset = 0;
    };

    // // /console.log("NumberFormatObject " + JSON.stringify(Intl.DateTimeFormat().resolvedOptions(), null, 2)); // this included in case we want to examine this object, but not much else of interest, and should use 

    // NEED TO COMPLETELY WORK the above, it's just bollocks so for now...

    // var deviceSecondsFromGMT = -d.getTimezoneOffset()*60; this was just wrong
    var deviceSecondsFromGMT = 0; // but this won't work apart form in UK winter time!!

    return {
      deviceIsDaylightSavingTime: (todayOffset == DST),
      deviceDaylightSavingTimeOffset: deviceDaylightSavingTimeOffset,
      deviceSecondsFromGMT: deviceSecondsFromGMT,
      deviceLocalTimeZoneIdentifier: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
   
  },

  convertVHSizeToMaxPixelsSize(vhSize, maxSizeInPixels){
    const pixelConversionOfVHSize = Math.round(window.innerHeight / (100 / vhSize));
     // we are assuming 100% font size is 16px
     const sizeInPX = maxSizeInPixels;
     var sizeInPXToUse = "";
     if (pixelConversionOfVHSize > sizeInPX) {
       sizeInPXToUse = sizeInPX; // shouldn't be larger than this   
     } else {
       sizeInPXToUse = pixelConversionOfVHSize;
     }
     // /console.log("calc'd size: " + sizeInPXToUse + 'px');
     
     return sizeInPXToUse;
  },

  validEmail(email){

      let result = false;

      result = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()\.,;\s@\"]+\.{0,1})+([^<>()\.,;:\s@\"]{2,}|[\d\.]+))$/.test(email);

      if (result === true) {
        // first test seemed to work quite well, except it doesn't actually catch if you have no ".com" etc!!!
        
        // so I was going to go down the path of checking for a .suffix, but it appears that 'dotless domains' were valid at least until 2013 and that bascially using regex to check email syntax validity is more likely to lead to user frustration, so I did nothing: see https://stackoverflow.com/questions/201323/how-can-i-validate-an-email-address-using-a-regular-expression just as one example

      }

      return result;
  },

  saveToUserPath(devEnv, pathItem, furtherDelay, loggingError){

   
    
    try {
    // console.log("starting saveToUserPath");

    if (devEnv === true) {
      // return;
    }
    if (timeOutId){
      // /console.log("clearing timeOutId: " + timeOutId);
      clearTimeout(timeOutId);
    }

    var existingItem = "no existing item";
    
    if (window.localStorage.getItem("tuuserpath") !== null) {
      existingItem = window.localStorage.getItem("tuuserpath"); // the problem with this is AT SOME POINT we are going to exceed localStorage capacity, so NOW we are only going to take existingItem forward if it has today's date in it and if it doesn't it starts afresh
    }

    let todayDate = new Date();
    let todayDateForLookup = todayDate.getDate() + " " + (todayDate.getMonth() + 1) + " " + todayDate.getFullYear();

    if (existingItem !== null) {

      if (existingItem.indexOf("Date: " + todayDateForLookup + " ::") === -1){
        existingItem = "Date: " + todayDateForLookup + " ::"; // start AFRESH
        // console.log("new day logged");
      } else {
        // console.log("AS is:: " + existingItem);
        // leave existingItem intact
      }
    } else {
      // console.log("starting new path");
      existingItem = "Date: " + todayDateForLookup + " ::";
      // /console.log("started user path: " + existingItem);
    }
    window.localStorage.setItem("tuuserpath", pathItem + "<p>" + existingItem);

    if (loggingError !== undefined && loggingError === true){
      // let it through, we only send email if by now we have an error to log, but the path is saved either way
    } else {
      return; // switching off, generates too many emails
    }
    

    timeOutId = setTimeout(() =>  {
      // /console.log("saveToUserPath schedule ::: " + furtherDelay);
      this.sendUserPathEmail();
      } , furtherDelay);

      // /console.log("timeOutId set = " + timeOutId);
    } catch (e) {

      const msg = "error message is: " + e.message + "   " + JSON.stringify(e, null, 2); 
      const params = {
        toEmail: "userpath@thanku.app",
        subject: "saveToUserPath ERROR " + new Date().toISOString(),
        body: msg
      };
  
      Parse.Cloud.run("sendEmail", params);
    }
  },

  async sendUserPathEmail(){
  
    
    var userDisplayname = "";

    try {

      if (window.localStorage.getItem("tudis") !== null){
        userDisplayname = ": " + window.localStorage.getItem("tudis");
      }

      const params = {
        toEmail: "userpath@thanku.app",
        subject: "User path" + userDisplayname + " " + new Date().toISOString(),
        body: window.localStorage.getItem("tuuserpath")
      };

      
      window.localStorage.setItem("tuuserpathsent", "sent");

      await Parse.Cloud.run("sendEmail", params);

    } catch (e) {

      const msg = "error message is: " + e.message + "   " + JSON.stringify(e, null, 2); 
      const params = {
        toEmail: "userpath@thanku.app",
        subject: "sendUserPathEmail ERROR " + new Date().toISOString(),
        body: msg
      };

      Parse.Cloud.run("sendEmail", params);
    }
  },

  /**
   * Parses substring between given begin string and end string.
   * @param beginString the begin string
   * @param endString the end string
   * @param originalString the original string
   * @returns the substring or null if either tag is not found
   */
  parseBetween(beginString, endString, originalString) {
    var beginIndex = originalString.indexOf(beginString);
    if (beginIndex === -1) {
        return null;
    }
    var beginStringLength = beginString.length;
    var substringBeginIndex = beginIndex + beginStringLength;
    var substringEndIndex = originalString.indexOf(endString, substringBeginIndex);
    if (substringEndIndex === -1) {
        return null;
    }
    return originalString.substring(substringBeginIndex, substringEndIndex);
  },

  setScreenHeightMultiplier(){

    var vhMult;
    let elem = document.getElementById("measure-vh");
    var elemHeight = elem.clientHeight ;
    // window.alert("viewport: " + document.documentElement.clientHeight + " real height: " + window.innerHeight + " or is it " + elemHeight + "?");

    if (document.documentElement.clientHeight >= elemHeight) {
        vhMult = 1;
    } else {
        vhMult = document.documentElement.clientHeight / elemHeight; // will be <1 to multiply with
    }
    // window.alert("this.vhMult * elemHeight: " + this.vhMult * elemHeight);

    return vhMult;
  },

  makeChart(ctx, chartData, dayToHighlight, userCurrencySymbol, labels, chartElemName, chartTitle){
    
    // const Chart = defineAsyncComponent(() =>
    //   require('chart.js/auto').default
    // );
    
    // const { Chart } =  defineAsyncComponent(() =>
    //   import('chart.js')
    // );

    // const { Chart } = await import('chart.js');

    // console.log("CHART: " + JSON.stringify(Chart, null, 2));
    const Chart = require('chart.js/auto').default;
    
    // import Chart from 'chart.js/auto'

    const greyColour = 'rgba(210, 210, 210, 0.8)';
    const blueColour = 'rgba(149, 174, 237, 0.9)';
    var mondayColour = dayToHighlight === 1 ? blueColour : greyColour;
    var tuesdayColour = dayToHighlight === 2 ? blueColour : greyColour;
    var wednesdayColour = dayToHighlight === 3 ? blueColour : greyColour;
    var thursdayColour = dayToHighlight === 4 ? blueColour : greyColour;
    var fridayColour = dayToHighlight === 5 ? blueColour : greyColour;
    var saturdayColour = dayToHighlight === 6 ? blueColour : greyColour;
    var sundayColour = dayToHighlight === 7 ? blueColour : greyColour;

    Chart.defaults.font.size = 9;

    let chartStatus = Chart.getChart(chartElemName); // <canvas> id
    if (chartStatus != undefined) {
      // console.log("destroying chart...")
      chartStatus.destroy();
            //(or)
    // chartStatus.clear();
    }

    const myChart = new Chart(ctx, {
        type: 'bar',
        responsive:true,
        data: {
            labels: labels,
            datasets: [{
                
                barPercentage: 0.6,
                data: chartData,
                backgroundColor: [
                    mondayColour,
                    tuesdayColour,
                    wednesdayColour,
                    thursdayColour,
                    fridayColour,
                    saturdayColour,
                    sundayColour
                ],
                borderWidth: 0
            }]
        },
        options: { 
            scales: {
                y: {
                    beginAtZero: true,
                    position: 'right',
                    ticks: {
                      maxTicksLimit: 5,
                        // Include a dollar sign in the ticks
                        callback: function(value, index, values) {
                        if (userCurrencySymbol==="%"){
                            return (value * 100) + userCurrencySymbol;
                        } else if (parseInt(value) >= 1000) {
                            return userCurrencySymbol + value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                        } else {
                            return userCurrencySymbol + value;
                        }
                    }
                    }
                    
                },
                x: {
                    barPercentage: 0.2
                },
            },
            plugins: {
                title: {
                    display: true,
                    align: 'start',
                    text: ' ' + chartTitle,
                    font: {
                            size: 12
                    }
                },
                legend: false,
                tooltip: {
                    callbacks: {
                        label: function(context) {
                            let label = context.dataset.label || '';

                            if (label) {
                                label += ': ';
                            }
                            if (context.parsed.y !== null) {
                              if (userCurrencySymbol==="%"){
                                  return (context.parsed.y * 100).toFixed(2) + userCurrencySymbol;
                              } else if (parseInt(context.parsed.y) >= 1000) {
                                  label +=  userCurrencySymbol + context.parsed.y.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                              } else {
                                  label +=  userCurrencySymbol + context.parsed.y.toFixed(2);
                              }
                            }
                            return label;
                        }
                    }
                }
            },
            
        }
    });
  }
}